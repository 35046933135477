import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import Faq from '../common/faq';
import ContactForm from '../common/contact-form';
// import Contact from '../common/contact';

function WrapMaster(props) {

    return (
      <>
       <div className="header header-scrolled" id="header">
            <div className="container-fluid container-lg">
                {/* Header */}
                <Header isOval={false} />
            </div>
        </div>
        {props.children}
        {/* FAQ */}
        {(typeof props.showFaq === "undefined" || props.showFaq === true) && (
          <Faq />
        )}
        {/* Contact Form */}
        {(typeof props.showContact === "undefined" || props.showContact === true) && (
          <ContactForm />
        )}
        {/* Contact */}
        {/* <Contact /> */}
        {/* Footer */}
        <Footer />
      </>
    );
  }
  
  export default WrapMaster;