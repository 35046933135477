import React, {useState} from "react";
import { Button } from "react-bootstrap";
import '../assets/css/pages/about.css?v=1';
import ModalVideo from "../components/modal/video-banner";

const AboutUs = () => {
  const [modalToggle, setModalToggle] = useState({video: false});

  const toggleModal = (e, modal_type, toggleState) => {
    try{
      if(toggleState){
        e.preventDefault();
      }
      setModalToggle({...modalToggle, [modal_type]: toggleState});
    }catch(error){
      console.log(error)
    }
  }

  return (
    <>
    <section>
    <div className="container-fluid container-lg">
      {/* Heading */}
      <div className="row heading">
        <div className="col-lg-12 col-12 col-sm-12">
          <h1 className="text-center">
          Supercharging brands the <span className="highlight">SuperGryd</span> way
          <br/>
            {/* <a href="/contact-us"> */}
              <Button
                className=""
                variant="primary"
                onClick={(e) => toggleModal(e, 'video', true)}
              >
                See SuperGryd in Action
              </Button>
            {/* </a> */}
          </h1>
        </div>
      </div>
    </div>
    </section>
    {/* Our Mission */}
    <section className="mission-con">
      <div className="container-fluid container-lg">
      <div className="justify-content-center row">
        <div className="col-xl-5 col-md-7 col-12 col-sm-12 order-2 order-md-1">
          <h2 className="pt-0 pb-2 pb-md-4">About SuperGryd</h2>
          <p className="content pe-md-4">SuperGryd is a B2B PaaS that simplifies travel by integrating local on-demand services, such as rides, food delivery, and medical assistance, into consumer apps. We enable seamless access to these services globally, ensuring travelers can use their preferred apps anywhere they go. Our platform also handles currency conversion and compliance, providing a smooth experience for both travelers and service providers.</p>
        </div>
        <div className="col-12 col-lg-4 col-md-5 col-sm-12 text-center text-md-end order-1 order-md-2">
          <img alt="About US" src={require("../assets/images/bg/about-us.png")} className="mb-4 mb-lg-0 img-fluid" />
        </div>
        {/* <div className="col-md-6 col-12 col-sm-12">
            <h2>Our Vision</h2>
            <p className="content">We believe in a world where travelers can access the services they require, regardless of their location. Our vision is to empower businesses and local service providers by connecting them with global travelers, creating a borderless ecosystem that simplifies how people interact with on-demand services internationally.</p>
        </div> */}
        {/* <div className="col-md-6 col-12 col-sm-12">
            <h2>What We Do</h2>
            <p className="content">SuperGryd is a B2B platform and solution enabler that integrates local on-demand service providers with global consumer brands. We provide an SDK that allows consumer brands to embed local services directly into their apps. This enhances the traveler’s experience by providing access to services they need, such as transportation, dining, and more, in their home currency.</p>
        </div> */}
        {/* <div className="col-md-6 col-12 col-sm-12">
            <h2>Why SuperGryd?</h2>
            <p className="content">For Hosts (consumer brands), we enable you to expand your service offerings and increase user loyalty and engagement without additional Customer Acquisition Costs (CAC).</p>
            <p className="content">For Sellers (local service providers), we connect you with international travelers, opening new revenue opportunities and expanding your market reach.</p>
            <p className="content">By creating seamless interactions between users and local services, SuperGryd drives value for all stakeholders, from global brands to local businesses.</p>
        </div> */}
        {/* <div className="col-md-6 col-12 col-sm-12">
            <h2>Our Global Ambition</h2>
            <p className="content">SuperGryd aims to operate in major travel corridors across Europe, America, and Asia, bringing together the best local on-demand services and global brands. Our platform is designed to help businesses scale, and our vision is to create a global network of trusted services for the modern traveler.</p>
        </div>
        <div className="col-md-6 col-12 col-sm-12">
            <h2>Join Us On The Journey</h2>
            <p className="content">We are building a world where local services meet global demand. Whether you're a consumer brand looking to enhance your app’s offerings or a local service provider aiming to expand your reach, SuperGryd is your trusted partner in creating a seamless, global experience for users everywhere.</p>
        </div> */}
        {/* <div className="col-md-6 col-12 col-sm-12">
          <div className="">
            <iframe
              height="450"
              srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/5Peo-ivmupE/?autoplay=1><img src=https://i.ytimg.com/vi/5Peo-ivmupE/sddefault.jpg alt='SuperGryd'><span><svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></span></a>`}
              title="SuperGryd"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen>
              </iframe>
            </div>
          </div> */}
        </div>
      </div>
    </section>
    {/* Founder */}
    <section className="founder-con">
      <div className="container-fluid container-lg">
        <h2 className="text-center">Meet the Founders</h2>
        <div className="row text-center">
          <div className="col-lg-4 col-12 col-sm-4 col-md-4">
            <a href="https://in.linkedin.com/in/jagan-girisaballa">
            <div className="cover-content">
              <img loading="lazy" className="cover" alt="Jagannath Girisaballa" src={require("../assets/images/founder/Jagannath-Girisaballa.png")} />
              <div className="overlay">
                <img loading="lazy" alt="" src={require("../assets/images/icon/linkedin.svg").default} />
              </div>
            </div>
            </a>
            <div className="designation">
              <h3 className="pt-3 pt-sm-4">Jagannath Girisaballa</h3>
              <p className="mt-2">Chief Operating Officer</p>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-sm-4 col-md-4 mt-3 mt-md-0">
            <a href="https://in.linkedin.com/in/reshmapendse">
            <div className="cover-content">
              <img loading="lazy" className="cover" alt="Reshma Pendse" src={require("../assets/images/founder/Reshma-Pendse.png")} />
              <div className="overlay">
                <img loading="lazy" alt="" src={require("../assets/images/icon/linkedin.svg").default} />
              </div>
            </div>
            </a>
            <div className="designation">
              <h3 className="pt-3 pt-sm-4">Reshma Pendse</h3>
              <p className="mt-2">Head of Product</p>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-sm-4 col-md-4 mt-3 mt-md-0">
            <a href="https://in.linkedin.com/in/sunilnair2404">
            <div className="cover-content">
              <img loading="lazy" className="cover" alt="Sunil Nair" src={require("../assets/images/founder/Sunil-Nair.png")} />
              <div className="overlay">
                <img loading="lazy" alt="" src={require("../assets/images/icon/linkedin.svg").default} />
              </div>
            </div>
            </a>
            <div className="designation">
              <h3 className="pt-3 pt-sm-4">Sunil Nair</h3>
              <p className="mt-2">Head of Strategy</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ModalVideo
      isShow={modalToggle.video}
      toggleModal={(e, state) => toggleModal(e, 'video', state)}
    />
    </>
  );
};

export default AboutUs;
