import React from 'react';
import { Button } from "react-bootstrap";

const Header = ({isOval=false}) => {
  const windowPath = window.location.pathname.split('/')[1];
  // const toggleHeaderSty = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   let selectHeader = document.querySelector('#header');
  //   if(selectHeader){
  //         if (scrolled > 150){
  //             selectHeader.classList.add('header-scrolled');
  //         }else if (scrolled <= 150){
  //             selectHeader.classList.remove('header-scrolled');
  //         }
  //   }
  
  // };
  //Toggle Mobile NavBar
  const toggleMobNav = (e) => {
      try{
          const selectNavBar = document.querySelector('#navbar');
          const selectMobIcon = document.querySelector('.mobile-nav-toggle');
          selectNavBar.classList.toggle('navbar-mobile');
          selectMobIcon.classList.toggle('fa-bars');
          selectMobIcon.classList.toggle('fa-multiply');
          document.querySelector('#contactBtnI').classList.toggle('d-none');
          e.target.classList.toggle('col-white');          
      }catch(error){
        console.log(error);
          // console.log('error opening mob navbar');
      }
  };
  //Activate Mobile Dropdown
  // const activateMobDrop = (evt) => {
  //     try{
  //         evt.preventDefault();
  //         // if (evt.target !== this) return;
  //         const selectNavBarId = document.querySelector('#navbar');
  //         if(selectNavBarId
  //             && selectNavBarId.classList.contains('navbar-mobile')
  //         ){
  //             const nextSib = evt.target.nextElementSibling;
  //             if(nextSib){
  //                 nextSib.classList.toggle('dropdown-active');
  //             }
  //         }
  //     }catch(e){
  //         // console.log(e);
  //     }
  // }
  
  // useEffect(() => {
      // window.addEventListener('scroll', toggleHeaderSty);
      // const selectMobDrop = document.querySelectorAll('.navbar .dropdown > a');
      // if(selectMobDrop.length){
      //     for (var i = 0 ; i < selectMobDrop.length; i++) {
      //         selectMobDrop[i].addEventListener('click', activateMobDrop ) ; 
      //     }
      // }

      // return () => {
      //     window.removeEventListener('scroll', toggleHeaderSty);
          // if(selectMobDrop.length){
          //     for (var i = 0 ; i < selectMobDrop.length; i++) {
          //         selectMobDrop[i].removeEventListener('click', activateMobDrop ) ; 
          //     }
          // }
      // }
  // }, []);

  return (
    <header>
        <div className="row">
          {/* Logo */}
          <div className="col-md-1 col-3 col-sm-2 logo">
            <a href="/">
              <img
                loading="lazy"
                alt="SuperGryd"
                src={require("../../assets/images/logo-hd.png")}
              />
              {/* <img
                loading="lazy"
                alt="SuperGryd"
                src={require("../../assets/images/logo-hd.png")}
              /> */}
            </a>
          </div>
          {/* Menu */}
          <div className="col-md-11 col-9 col-sm-10">
            {/* <div className={`${isOval ? 'half-oval' : ''} menu`}> */}
            <div className="menu d-flex" id="navbar">
            <nav>
              <ul className="main-menu">
                {/* <li><a className={`${windowPath === '' ? 'active' : ''}`} href="/">Features</a></li> */}
                <li><a className={`${windowPath === 'hosts' ? 'active' : ''}`} href="/hosts">For Hosts</a></li>
                <li><a className={`${windowPath === 'sellers' ? 'active' : ''}`} href="/sellers">For Sellers</a></li>
                <li><a className={`${windowPath === 'about-us' ? 'active' : ''}`} href="/about-us">About Us</a></li>
                {/* <li><a className={`${windowPath === 'blog' ? 'active' : ''}`} href="/blog">Blog</a></li> */}
              </ul>
            </nav>
            {/* Contact Us */}
            <div className="float-end d-flex">
                <a href="#contactUs">
                  <Button
                    className="contact float-end float-lg-none"
                    id="contactBtnI"
                    variant="primary"
                    size="md"
                  >Contact Us</Button>
                </a>
                {/* Mobile Menu */}
                <i
                  className="fa fa-bars me-2 mobile-nav-toggle ms-4"
                  onClick={(e) => toggleMobNav(e)}
                ></i>
            </div>
            </div>
          </div>
        </div>
    </header>
  );
};

export default Header;