import ContactForm from "../components/common/contact-form";
const ContactUs = () => {
  return (
    <>
    <section className="pb-4">
        <div className="container-fluid container-lg">
          {/* Heading */}
          <div className="row heading">
            <div className="col-lg-12 col-12 col-sm-12">
              <h1 className="text-center">Contact Us</h1>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
    </>
  );
};

export default ContactUs;
