import React, {useState} from "react";
import { Button } from "react-bootstrap";
import Partner from "../components/common/partner";
import '../assets/css/pages/home.css?v=2';
//Modal
import ModalVideo from "../components/modal/video-banner";
// Images/SVG
import ImgInte1 from '../assets/images/feature/integration-1.svg';
import ImgInte2 from '../assets/images/feature/integration-2.svg';
import ImgCon1 from '../assets/images/feature/contract-1.jpg';
import ImgCon2 from '../assets/images/feature/contract-2.png';
// import ImgAna1 from '../assets/images/feature/analytics-1.svg';
// import ImgAna2 from '../assets/images/feature/analytics-2.svg';
import ImgAna2 from '../assets/images/feature/dashboard-ani-2.svg';
import ImgAna1 from '../assets/images/feature/dashboard-ani-1.svg';
import ImgAi1 from '../assets/images/feature/ai-1.svg';
import ImgAi2 from '../assets/images/feature/ai-2.svg';
// import ImgAna1 from '../assets/images/feature/dashboard.png';
// import ImgBanner1 from '../assets/images/feature/banner.png';
import GifBanner1 from '../assets/images/feature/banner.gif';

const LandingPage = () => {
  const [modalToggle, setModalToggle] = useState({video: false});

  const toggleModal = (e, modal_type, toggleState) => {
    try{
      if(toggleState){
        e.preventDefault();
      }
      setModalToggle({...modalToggle, [modal_type]: toggleState});
    }catch(error){
      console.log(error)
    }
  }
  return (
    <>
    <section className="banner">
      <div className="container-fluid container-lg">
        {/* Header */}
        {/* <Header isOval={true} /> */}
        {/* Heading */}
        <div className="row heading">
          <div className="col-lg-12 col-12 col-sm-12">
            <h1 className="text-center">
              <span className="highlight">From Local to Global</span><br/>
              Elevate your app capabilities, Expand your reach<br/>
              {/* We Help Brands Grow Internationally by Integrating <span className="h-word">On-Demand</span> Services Within Their Apps<br/> */}
              {/* <a href="/contact-us" className="justify-content-center d-flex"> */}
                <div className="justify-content-center d-flex">
                <Button
                  className=""
                  variant="primary"
                  onClick={(e) => toggleModal(e, 'video', true)}
                >
                  See SuperGryd in Action
                </Button>
                </div>
              {/* </a> */}
              <span>
                <img
                  className="arrow float-end"
                  loading="lazy"
                  alt="Arrow"
                  src={require("../assets/images/arrow.png")}
                />
              </span>
            </h1>
          </div>
        </div>
        {/* Video */}
        <div className="justify-content-center row video-con mt-3 text-center">
          <div className="col-md-9 col-12">
            <img src={GifBanner1} alt="SuperGryd"/>
            {/* <div className="iframe-container">
              <iframe
                height="450"
                srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/5Peo-ivmupE/?autoplay=1><img src=https://i.ytimg.com/vi/5Peo-ivmupE/sddefault.jpg alt='SuperGryd'><span><svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></span></a>`}
                title="SuperGryd"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
              </iframe>
              <div className="btn-cont-1">
                <p className="btn-1">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      className=""
                      loading="lazy"
                      alt="Heart"
                      src={require("../assets/images/icon/heart.svg").default}
                    />&nbsp;
                    Health & Wellness
                  </Button>
                </p>
                <p className="btn-2 float-end">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      loading="lazy"
                      alt="Car"
                      src={require("../assets/images/icon/car.svg").default}
                    />&nbsp;
                    Ride
                  </Button>
                </p>
              </div>
              <div className="btn-cont-2 pb-0">
                <p className="btn-4 text-end">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      loading="lazy"
                      alt="Experiencess"
                      src={require("../assets/images/icon/shines.svg").default}
                    />&nbsp;Experiences</Button>
                </p>
                <p className="btn-3">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      loading="lazy"
                      alt="Food"
                      src={require("../assets/images/icon/food.svg").default}
                    />&nbsp;
                    Food
                  </Button>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
    {/* Desc */}
    <section className="super-detail">
      <div className="container-fluid container-lg">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <img src={require("../assets/images/bg/quotation.svg").default} className="first-img" alt="Quotation" />
            <img src={require("../assets/images/bg/quotation.svg").default} className="last-img" alt="Quotation" />
            <p className="text-center">SuperGryd enables seamless integration of local on-demand services into consumer apps, allowing travelers to access rides, food delivery, events, and medical services when they travel worldwide</p>
          </div>
        </div>
      </div>
    </section>
    {/* Feature */}
    <section>
      <div className="container-fluid container-lg feature">
        <h2 className="text-center title"><span>Features</span></h2>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-11 col-sm-4 col-md-4">
            <div className="card mt-md-4 tagline d-flex d-sm-none">
              <h3>Expanding your reach</h3>
            </div>
            <div className="contract mt-3 d-flex d-sm-none">
              <h3>One Contract</h3>
              <img
                className="first card"
                alt="One Contract"
                // src={require("../assets/images/feature/contract.svg").default}
                src={ImgCon1}
                onMouseEnter={e => e.currentTarget.src = ImgCon2}
                onMouseLeave={(e) => e.currentTarget.src =  ImgCon1}
              />
              {/* <img src={ImgCon1} className="image" alt="normal" /> */}
              {/* <img src={ImgCon2} className="image-hover" alt="hover" /> */}
            </div>
            <div className="integration mt-3 mt-sm-0 d-none d-sm-flex">
              <h3>No code integration</h3>
              <img className="first card"
                alt="No code integration"
                src={ImgInte1}
                onMouseEnter={e => e.currentTarget.src = ImgInte2}
                onMouseLeave={(e) => e.currentTarget.src =  ImgInte1}
              />
            </div>
            <div className="card mt-md-4 mt-3 tagline d-none d-sm-flex">
              <h3>Built for simplicity</h3>
            </div>
          </div>
          <div className="col-lg-4 col-11 col-sm-4 col-md-4 mt-3 mt-sm-0">
            <div className="contract d-none d-sm-flex">
              <h3>One Contract</h3>
              <img
                className="first card"
                alt="One Contract"
                // src={require("../assets/images/feature/contract.svg").default}
                src={ImgCon1}
                onMouseEnter={e => e.currentTarget.src = ImgCon2}
                onMouseLeave={(e) => e.currentTarget.src =  ImgCon1}
              />
              {/* <img src={ImgCon1} className="image" alt="normal" /> */}
              {/* <img src={ImgCon2} className="image-hover" alt="hover" /> */}
            </div>
            <div className="integration d-flex d-sm-none">
              <h3>No code integration</h3>
              <img className="first card"
                alt="No code integration"
                src={ImgInte2}
                // onMouseEnter={e => e.currentTarget.src = ImgInte2}
                // onMouseLeave={(e) => e.currentTarget.src =  ImgInte1}
              />
            </div>
            <div className="analytic mt-3 card">
              <h3>Analytics</h3>
              <img
                className="first d-none d-sm-flex"
                alt="Analytics"
                src={ImgAna2}
                onMouseEnter={e => e.currentTarget.src = ImgAna1}
                onMouseLeave={(e) => e.currentTarget.src =  ImgAna2}
              />
              <img
                className="first d-flex d-sm-none"
                alt="Analytics"
                src={ImgAna1}
                // onMouseEnter={e => e.currentTarget.src = ImgAna2}
                // onMouseLeave={(e) => e.currentTarget.src =  ImgAna1}
              />
            </div>

            {/* <div className="card payment">
              <h3>Global Payments</h3>
              <img className="first" alt="" src={require("../assets/images/feature/payment.svg").default}/>
            </div> */}
            
            {/* <div className="card tagline mt-3 d-none d-md-flex">
              <h3>Supercharge your app with SuperGryd</h3>
            </div> */}
          </div>
          <div className="col-lg-4 col-11 col-sm-4 col-md-4 mt-3 mt-sm-0">
            <div className="ai">
              <h3>AI-Powered Support</h3>
              {/* <img className="first" alt="" src={require("../assets/images/feature/ai.svg").default}/> */}
              <img
                className="first card d-none d-sm-flex"
                alt="AI-Powered Support"
                src={ImgAi1}
                onMouseEnter={e => e.currentTarget.src = ImgAi2}
                onMouseLeave={(e) => e.currentTarget.src =  ImgAi1}
              />
              <img
                className="first card d-flex d-sm-none"
                alt="AI-Powered Support"
                src={ImgAi2}
                // onMouseEnter={e => e.currentTarget.src = ImgAi2}
                // onMouseLeave={(e) => e.currentTarget.src =  ImgAi1}
              />
            </div>
            <div className="card mt-md-4 mt-3 tagline d-none d-sm-flex">
              <h3>Expanding your reach</h3>
            </div>
            <div className="card mt-md-4 mt-3 tagline d-flex d-sm-none">
              <h3>Built for simplicity</h3>
            </div>
          </div>
          {/* <div className="col-lg-12 col-11 col-sm-4 col-md-4 mt-3 mt-sm-0">
            <img src={ImgDash} alt="Dashboard" />
          </div> */}
        </div>
        {/* <div>
            <a href="#contactUs" className="justify-content-center d-flex btn-cstm mt-5">
              <Button
                className=""
                variant="primary"
              >
                Know More
              </Button>
            </a>
          </div> */}
      </div>
    </section>
    {/* Partner */}
    <Partner />
    <ModalVideo
      isShow={modalToggle.video}
      toggleModal={(e, state) => toggleModal(e, 'video', state)}
    />
    </>
  );
};

export default LandingPage;
