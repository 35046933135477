import { Accordion } from "react-bootstrap";

const faqData = [{ques: 'What is SuperGryd and how does it work?',
                  ans: 'SuperGryd is a B2B platform that integrates local on-demand services like ride-hailing, food delivery, and experiences into travelers’ preferred apps. Sellers offering on-demand services can integrate their APIs with SuperGryd to access international travelers. Host apps can use the SuperGryd SDK to offer these services seamlessly to their users when they travel.'},
                  {ques: 'Who are SuperGryd’s partners?',
                  ans: 'SuperGryd partners with local on-demand service providers such as ride-hailing, food delivery, and medical services apps in destination countries. We also collaborate with major consumer brands and apps with large Monthly Active Users (MAUs) to enable them to offer these integrated services to their users.'},
                  {ques: 'What is a Host in SuperGryd`s ecosystem?',
                    ans: 'A Host is a consumer brand or app with high number of active international travelers that partners with SuperGryd to offer local services to their users while traveling. Hosts benefit from increased user engagement and loyalty without additional Customer Acquisition Costs (CAC).'},
                  {ques: 'What is a Seller in SuperGryd`s ecosystem?',
                    ans: 'A Seller is a local on-demand service provider that partners with SuperGryd to offer their services to international travelers via Host apps. Sellers gain access to a broader customer base, increasing their revenue potential.'},
                  {ques: 'Which regions does SuperGryd operate in?',
                    ans: 'SuperGryd is focused on the USA<> Europe corridor which represents the highest volume of travelers worldwide. We also have operations in India, Middle East and South East Asia. Our goal is to create a global network that allows users to access trusted local services across multiple countries through their favorite apps.'},
                  {ques: 'How can I become a SuperGryd partner?',
                    ans: 'If you are a local service provider or a consumer brand looking to integrate SuperGryd’s services into your platform, please <a href="#contactUs" title="Contact Us">contact us</a>. Our team will guide you through the integration process and help you leverage our platform to expand your business.'}
                ];

const Faq = () => {
  return (
    <section className="faq">
      <div className="container-fluid container-lg">
        <h2 className="text-center title">Frequently Asked Questions</h2>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-8">
            {faqData.map((d_faq, d_key) => (
               <Accordion className="mb-4" key={`faq_${d_key}`}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header as="h3">{d_faq.ques}</Accordion.Header>
                  <Accordion.Body>
                    <p dangerouslySetInnerHTML={{ __html: d_faq.ans }}></p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;