import React from "react";
import '../../assets/css/pages/about.css?v=1';

const PrivacyPolicy = () => {

  return (
    <>
    <section>
    <div className="container-fluid container-lg">
      {/* Heading */}
      <div className="row heading">
        <div className="col-lg-12 col-12 col-sm-12">
          <h1 className="text-center">Privacy Policy</h1>
        </div>
      </div>
    </div>
    </section>
    <section>
      <div className="container-fluid container-lg">
      <div className="row justify-content-center list-num privacy">
        <div className="col-md-8 col-12 col-sm-12">
            <p>Effective Date: [7th October 2024]</p>
            <p>At SuperGryd Technologies, your privacy is our top priority. This Privacy Policy outlines how we collect, use, share, and protect your personal information when you use our platform. We are committed to safeguarding your data and ensuring transparency in how it is handled.</p>
            <ol>
                <li>
                    <h3>Information We Collect</h3>
                    <p>When you interact with SuperGryd, we may collect the following types of information:</p>
                    <p>- Personal Information: Name, email address, contact details, and payment information.</p>
                    <p>- Usage Information: Details on how you use our platform, including app interactions, IP addresses, browser types, and usage logs.</p>
                    <p>- Location Information: If you enable location services, we collect information about your geographic location to connect you with local services.</p>
                    <p>- Device Information: Data about the device you use to access SuperGryd, such as your operating system, mobile device ID, and network information.</p>
                </li>
                <li>
                    <h3>How We Use Your Information</h3>
                    <p>We use the information we collect for the following purposes:</p>
                    <p>- To provide and improve our services.</p>
                    <p>- To process transactions and manage billing.</p>
                    <p>- To personalize the user experience, including recommending local services based on your location and preferences</p>.
                    <p>- To communicate with you regarding updates, promotions, or customer support.</p>
                    <p>- To ensure compliance with legal and regulatory requirements.</p>
                </li>
                <li>
                    <h3>Sharing Your Information</h3>
                    <p>SuperGryd does not sell or rent your personal information to third parties. However, we may share your information with trusted third parties under the following circumstances:</p>
                    <p>- Local Service Providers: We share relevant data with local on-demand service providers (Sellers) to facilitate the services you request through your preferred apps (Hosts).</p>
                    <p>- Payment Processors: To process transactions securely and in compliance with applicable laws.</p>
                    <p>- Compliance with Law: If required by law, we may share your information to comply with legal obligations or protect our rights.</p>
                    <p>- Third-Party Service Providers: We may share your information with vendors who assist with platform maintenance, marketing, analytics, and customer support.</p>
                </li>
                <li>
                    <h3>Data Security</h3>
                    <p>We take the security of your data seriously. SuperGryd implements industry-standard security measures to protect your personal information from unauthorized access, disclosure, or alteration. We use encryption, firewalls, and secure data storage to safeguard your data.</p>
                </li>
                <li>
                    <h3>Your Rights</h3>
                    <p>You have control over your personal data. Depending on your location and applicable laws, you may have the following rights:</p>
                    <p>- Access: Request a copy of the personal data we hold about you.</p>
                    <p>- Correction: Ask us to update or correct any inaccuracies in your personal data.</p>
                    <p>- Deletion: Request the deletion of your personal data, subject to certain legal exceptions.</p>
                    <p>- Opt-Out: Unsubscribe from marketing communications or opt-out of certain data processing practices.</p>
                    <p>To exercise any of these rights, please contact us at <a href={`mailto:${process.env.REACT_APP_EMAIL}`} target="_blank" rel="noreferrer">{process.env.REACT_APP_EMAIL}</a>.</p>
                </li>
                <li>
                    <h3>Cookies and Tracking Technologies</h3>
                    <p>SuperGryd uses cookies and similar technologies to enhance your experience on our platform. Cookies help us understand user behavior, personalize your experience, and deliver targeted advertisements. You can control cookies through your browser settings.</p>
                </li>
                <li>
                    <h3>Data Retention</h3>
                    <p>We retain your personal information for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. Once personal data is no longer needed, we securely delete or anonymize it.</p>
                </li>
                <li>
                    <h3>International Data Transfers</h3>
                    <p>SuperGryd operates in multiple countries. If your data is transferred outside of your home country, we ensure that appropriate safeguards are in place to protect your personal information in compliance with applicable data protection laws.</p>
                </li>
                <li>
                    <h3>Third-Party Links</h3>
                    <p>Our platform may contain links to third-party websites or services. SuperGryd is not responsible for the privacy practices or content of these third-party sites. We recommend reviewing the privacy policies of those websites before providing your personal information.</p>
                </li>
                <li>
                    <h3>Children’s Privacy</h3>
                    <p>SuperGryd is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we discover that we have unintentionally collected information from a child, we will take steps to delete the information promptly.</p>
                </li>
                <li>
                    <h3>Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website with the effective date.</p>
                </li>
                <li>
                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact us at: Email: <a href={`mailto:${process.env.REACT_APP_EMAIL}`} target="_blank" rel="noreferrer">{process.env.REACT_APP_EMAIL}</a></p>
                </li>
            </ol>
            <div>
            </div>
        </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default PrivacyPolicy;
