import "./../assets/css/pages/error.css";

function NotFound() {
    return (
        <div className="error-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="error-content">
                            <h1>Opps!</h1>
                            <h2>Page Not Found</h2>
                            <p>Sorry, the page you were looking for doesn't exist anymore or might have been moved.</p>
                            <div className="button">
                                <a href="/" className="btn">Go To Home Page</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;