import React, {useState, useRef} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ValidationHelper from "../../helper/validation";
// import {LoaderMedium} from './../../components/loader';
import {post_api_call} from './../../actions/action-creators/common';
import { Button } from "react-bootstrap";
const validationRules = {
  name: 'required|max:100',
  email: 'required|email|max:150',
  company_name: 'required|max:100',
  message: 'optional|max:250'
};
const initialData = {name: '', email: '', company_name: '', message: ''};
const ContactForm = () => {
  const captchaRef = useRef(null);
  //Forms
  const [contactData, setContactData] = useState(initialData);
  const [errorData, setErrorData] = useState([]);
  const [isProcess, setIsProcess] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  //Update Input
  const updateInput = (field, value) => {
    try{
        if(errorData.length){
          setErrorData([]);
        }
        setBtnDisabled(false);
        // if(field === 'mobile_number'){
        //     if(value.length < 16){
        //         value = value.replace(/[^0-9]/g, '');
        //     }else{
        //         return false;
        //     }
        // }else if(input_type === 'input'
        //     || input_type === 'textarea'
        // ){
            value = value.trimStart();
            if(field === 'name'){
                value = value.replace(/[^a-zA-Z. ]/g, '')
                  .replace('  ', ' ');
            }
        // }
          
        var fieldData = {...contactData};
        fieldData[field] = value;
        setContactData({...fieldData});
        //Validation
        const validation = ValidationHelper.validation(fieldData, validationRules).error;
        const captchaValue = captchaRef.current.getValue()
        if (!captchaValue) {
          validation.push("Please verify the reCAPTCHA!");
        }
        validation.length ? setBtnDisabled(true) : setBtnDisabled(false);
        setIsSuccess(false);
    }catch(e){
        console.log(e);
        setBtnDisabled(true);
        setErrorData(["Error processing your request! Please try after some time."]);
        // error_api_call({error: `common|contact-form|updateInput|${e}`});
    }
  }
  //Send API Request
  const sendContactReq = async(e) => {
    try{
        e.preventDefault();
        setErrorData([]);
        setBtnDisabled(false);
        const captchaValue = captchaRef.current.getValue()
        if (!captchaValue) {
          setErrorData(["Please verify the reCAPTCHA!"]);
          setBtnDisabled(true);
        } else {
          setIsProcess(true);
        //Validation
        // const validation = ValidationHelper.validation(fieldformDataReferee, validationRules).error;
        // if(validation.length){
        //   setErrorData([...validation]);
        //   setBtnDisabled(true);
        //   setIsProcess(false);
        // }else{
          var apiFormData = new FormData();
          const basicDataKeys = ['name',
                                  'email',
                                  'company_name',
                                  'message'
                              ];
                    
          for (var key in basicDataKeys) {
              if (contactData.hasOwnProperty(basicDataKeys[key])) {
                  apiFormData.append(`${basicDataKeys[key]}`, contactData[basicDataKeys[key]]);
              }
          }
          // setIsSuccess(true);//delete
          // setIsProcess(false);//delete
          // return;
          //Send Data to Server
          const contactApi = await post_api_call(`contact`, apiFormData);
          if(typeof contactApi.status !== "undefined" && contactApi.status === 200){
            setIsSuccess(true);
            setContactData({...initialData});
            // setBtnDisabled(true);
          }else{
            if(typeof contactApi.message !== "undefined"){
              setErrorData([updateFellow.message]);
            }else{
              setErrorData(["Error processing your request! Please try after some time."]);
            }
          }
          setIsProcess(false);
        }
    }catch(error){
        console.log(error);
        setErrorData(['Something Went Wrong, Please Try After Some Time']);
        setIsProcess(false);
        // error_api_call({error: `common|contact-form|sendContactReq|${e}`});
    }
  }
  //Recaptcha
  function onCaptchaChange(value) {
    if(value){
      setErrorData([]);
      const validation = ValidationHelper.validation(contactData, validationRules).error;
      validation.length ? setBtnDisabled(true) : setBtnDisabled(false);
    }
  }
  
  return (
    <section className="con-form-sec" id="contactUs">
      <div className="container-fluid container-lg">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <h2 className="pt-0 pb-0 pb-md-3">Ready to Take Your App Global?</h2>
            <p>See how SuperGryd can help you unlock new markets and enhance user experiences. Our team is here to help you every step of the way</p>
            <div>
            <ul className="cont-details">
              <li>
                <div>
                  <div className="icon">
                    <i className="fa fa-2x fa-map-marker-alt"></i>
                  </div>
                </div>
                <div>
                  <h3>Our Address</h3>
                  <div>
                    <p className="mb-0"><b>Supergryd Technologies Corporation</b></p>
                    <p>16192 Coastal Highway, Lewes, Delaware 19958, USA</p>
                  </div><br/>
                  <div>
                    <p className="mb-0"><b>Supergryd Technologies Private Limited</b></p>
                    <p>#75, 2nd floor, JP Nagar 4th Phase, 16th Main, Bangalore – 560078 INDIA</p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                <div className="icon">
                  <i className="fa fa-2x fa-phone-volume"></i>
                </div>
                </div>
                <div>
                  <h3>Contact</h3>
                  <p>
                    <span>Mail: </span>
                    <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>
                  </p>
                </div>
              </li>
            </ul>
            </div>
          </div>
          <div className="col-lg-1 d-none d-lg-flex"></div>
          <div className="col-12 col-sm-12 col-md-6 col-md-6">
            <form className="card p-4">
                <div className="mb-4">
                    <label  className="form-label fw-medium">Name<em>*</em></label>
                    <input type="text" className="form-control form-control-lg" value={contactData.name} placeholder="Enter Your Name" onChange={(e) => updateInput('name', e.target.value)} />
                </div>
                <div className="mb-4">
                    <label className="form-label fw-medium">Email ID<em>*</em></label>
                    <input type="email" className="form-control form-control-lg" value={contactData.email} placeholder="Enter Your Email Address" onChange={(e) => updateInput('email', e.target.value)} />
                </div>
                <div className="mb-4">
                    <label className="form-label fw-medium">Company<em>*</em></label>
                    <input type="text" className="form-control form-control-lg" value={contactData.company_name} placeholder="Enter Your Company Name" onChange={(e) => updateInput('company_name', e.target.value)} />
                </div>
                <div className="mb-4">
                    <label className="form-label fw-medium">Your Message</label>
                    <textarea type="text" className="form-control" value={contactData.message} rows={5} placeholder="Tell us everything" onChange={(e) => updateInput('message', e.target.value)} />
                </div>
                <div className="mb-4">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={onCaptchaChange}
                />
                </div>
                {/* Error */}
                <div className="col-md-12 col-12 col-sm-12">
                  {errorData.length > 0 && (
                    <div className="alert alert-danger" role="alert">
                      <p className="mb-0 text-center">{errorData[0]}</p>
                    </div>
                  )}
                  {isSuccess && (
                    <div className="alert alert-success" role="alert">
                      <p className="mb-0 text-center">Thank you for your interest! We have received your response and will get back to you shortly.</p>
                    </div>
                  )}
                </div>
                
                <p className="text-center mb-2 loader-con">
                  {!isProcess
                  ?
                      isBtnDisabled || isSuccess
                      ?
                          <Button
                            className="w-100"
                            variant="primary"
                            size="lg"
                            disabled
                          >Submit</Button>
                      :
                        <Button
                          className="w-100"
                          variant="primary"
                          size="lg"
                          onClick={(e) =>sendContactReq(e)}
                        >Submit</Button>
                  :
                    <Button
                      className="w-100"
                      disabled
                      variant="primary"
                      size="lg"
                      onClick={(e) =>sendContactReq(e)}
                    >
                      <img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...
                    </Button>
                  }
                </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;