import { Button } from "react-bootstrap";

const IdeaSection = () => {
  return (
    <>
     <section className="idea-con">
        <div className="container-fluid container-lg">
          <div className="row justify-content-center text-center">
            <div className="col-12 col-sm-12 col-md-12">
              <h2 className="pb-3 pt-0">Got Another Idea?</h2>
              <p>Let's discuss it! Share your thoughts and we'll work together to bring them to life.</p>
              <a href="#contactUs">
              <Button
                className="mt-4"
                variant="primary"
                // variant="outline-primary"
                // size="lg"
              >Talk to us</Button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IdeaSection;
