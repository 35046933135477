import { Button } from "react-bootstrap";

const SdkNavigate = (props) => {
  return (
    <>
    <section className="sdk-con">
      <div className="container-fluid container-lg">
        <div className="row">
        <h2 className="pt-0 pb-2">SuperGryd integrates so you don’t have to</h2>
          <div className="col-12 col-sm-12 col-md-6 col-lg-5">
            <p className="mb-0">{props.content}</p>
            {/* <a href="#contactUs">
            <Button
              className="mt-3"
              variant="outline-primary"
              size="lg"
            >Learn More</Button>
            </a> */}
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default SdkNavigate;
