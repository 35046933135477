const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid container-lg">
        <div className="row text-center text-sm-start">
          <div className="col-12 col-sm-3 col-md-3 logo">
            <a href="/">
            <img
              loading="lazy"
              alt="SuperGryd"
              src={require("../../assets/images/logo.png")}
            />
            </a>
            {/* <hr /> */}
            {/* <a href="mailto:admin@supergryd.com" className="email">admin@supergryd.com</a> */}
          </div>
          <div className="col-12 col-sm-3 col-md-3">
           <ul className="mt-5 mt-sm-0">
              {/* <li><a href="/">Features</a></li> */}
              <li><a href="/hosts">For Hosts</a></li>
              <li><a href="/sellers">For Sellers</a></li>
            </ul>
          </div>
          <div className="col-12 col-sm-3 col-md-3">
            <ul className="mt-5 mt-sm-0">
              <li><a href="/about-us">About Us</a></li>
              <li><a href="/contact-us">Contact Us</a></li>
              {/* <li><a href="#contactUs">Book a Demo</a></li> */}
              {/* <li><a href="/privacy-policy">Privacy Policy</a></li> */}
            </ul>
          </div>
          <div className="col-12 col-sm-3 col-md-3">
            <p className="social-head mt-5 mt-sm-0">Follow us on</p>
            <ul className="social justify-content-center justify-content-md-start">
              <li><a href="https://www.linkedin.com/company/supergryd/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a></li>
              {/* <li><a href="#"><i className="fa-brands fa-x-twitter"></i></a></li> */}
              {/* <li><a href="#"><i className="fa-brands fa-youtube"></i></a></li> */}
            </ul>
          </div>
        </div>
        <hr />
        <p className="copyright">Copyright © {currentYear} SuperGryd. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;