import { Button } from "react-bootstrap";

const Partner = () => {
  return (
    <section>
      <div className="container-fluid container-sm partner">
        <h2 className="text-center title">How would you like to <span>partner with us?</span></h2>
        <div className="row mt-md-3">
          <div className="col-lg-6 col-12 col-sm-12">
            <div className="me-lg-2 mb-4 mb-lg-0">
            <div className="row p-container m-1 m-sm-0 card">
              <div className="col-md-7 col-12 col-sm-7 mt-sm-4 pe-sm-0">
                <h3>As a Host</h3>
                <p>Add our network of ridehailing, travel experiences, food partners to your app, so your users can use it abroad.</p>
                <a href="/hosts">
                  <Button
                    variant="outline-primary"
                    size="lg"
                  >Know more</Button>
                </a>
              </div>
              <div className="col-md-5 col-12 col-sm-5">
                <div className="partner-img-first">
                  <img className="first" alt="" src={require("../../assets/images/partner/bg.svg").default}/>
                  <img className="second" alt="" src={require("../../assets/images/partner/host.svg").default}/>
                  <img className="third" loading="lazy" alt="" src={require("../../assets/images/partner/puzzle.png")} />
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 col-sm-12">
            <div className="ms-lg-2">
            <div className="row p-container m-1 m-sm-0 card">
              <div className="col-md-7 col-12 col-sm-7 mt-sm-4 pe-sm-0">
                <h3>As a Seller</h3>
                <p>You provide services and receive additional revenue at zero user acquisition cost.</p>
                <a href="/sellers">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="mt-md-3"
                  >Know more</Button>
                </a>
              </div>
              <div className="col-md-5 col-12 col-sm-5 mt-sm-4">
                <div className="partner-img-last">
                  <img loading="lazy" alt="" src={require("../../assets/images/partner/seller.svg").default} />
                </div>
              </div>
            </div>
            </div>
          </div>
          <div>
            <a href="#contactUs" className="justify-content-center d-flex btn-cstm mt-5">
              <Button
                className=""
                variant="primary"
              >
                Partner with us
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;