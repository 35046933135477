import axios from "axios";
// import {errorResponse} from "./response";
const apiBaseUrl = process.env.REACT_APP_API_URL;
const headers = {
  'Content-Type': 'application/json',
//   'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
};

export const post_api_call = async(api, payload) => {
  try{
        const data = await axios.post(`${apiBaseUrl}/${api}`, payload, {headers});
        return data.data;
  }catch(error){
    // if(!(typeof payload.fetch_type !== "undefined"
    //   && payload.fetch_type === 'logout')
    // ){
    //   return errorResponse({error, alert_error: 1});
    // }
    // console.log(error)
    return;
  }
};
/*
 * Call Error API
 */
// export const error_api_call = async(payload) => {
//     try{
//       await axios.post(`${apiBaseUrl}/app-error`, {app: 'website', error: payload.error}, {headers});
//       return 'Api Called';
//     }catch(error){
//       console.log(error)
//       return 'Api Called:Error';
//     }
//   };