import React from "react";
// Images
import MobileCons from '../assets/images/flow/consumer-app.png';
import MiddleImg from '../assets/images/flow/arrow.png';
import MiddleImgMob from '../assets/images/flow/arrow-down.png';
import MobileMulti from '../assets/images/flow/mobile.png';
import SdkNavigate from "../components/section/sdk-navigate";
import '../assets/css/pages/host.css?v=2';
import IdeaSection from "../components/section/idea-section";

const ForHosts = () => {
  return (
    <>
      <section className="host-sec">
        <div className="container-fluid container-lg">
          {/* Heading */}
          <div className="row heading">
            <div className="col-lg-12 col-12 col-sm-12">
              <h1 className="text-center">
              <span className="tagline">We streamline, you expand</span><br/>
              Seamlessly connect your travelers to <br/><span className="h-word">on-demand</span> services in <span className="highlight">international destinations</span><br/>
                {/* <a href="#contactUs" className="justify-content-center d-flex">
                  <Button
                    className=""
                    variant="primary"
                  >
                    Partner with us
                  </Button>
                </a> */}
                <span>
                  <img
                    // className="arrow float-end"
                    className="arrow-fxd float-end"
                    loading="lazy"
                    alt="Arrow"
                    src={require("../assets/images/arrow.png")}
                  />
                </span>
              </h1>
            </div>
          </div>
           {/* Flow */}
           <div className="justify-content-center row mt-3 align-items-center text-center flow-content">
            {/* <div className="col-md-12 col-12"> */}
              {/* <div > */}
                {/* <div className=""> */}
                <div className="col-8 col-sm-3 col-md-3 col-lg-2">
                  <img src={MobileCons} alt="Consumer APP"/>
                  {/* <img src={require('../assets/images/flow/consumer-app-k.png')} /> */}
                </div>
                <div className="col-8 col-sm-6 col-md-6">
                  <img src={MiddleImg} alt="SuperGryd Services" className="d-none d-sm-flex w-100"/>
                  <img src={MiddleImgMob} alt="SuperGryd Services" className="d-sm-none w-100"/>
                </div>
                <div className="col-8 col-sm-3 col-md-3 col-lg-2">
                  <img src={MobileMulti} alt="SuperGryd APP"/>
                </div>
                {/* </div> */}
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </section>
      {/* SDk */}
      <SdkNavigate
        content={"SuperGryd offers a fully customizable SDK to seamlessly integrate a range of on-demand services directly into your app, empowering you to provide enhanced user experiences without the complexity. This no-code integration takes just a few weeks instead of months!"}
      />
      {/* Opportunity */}
      <section>
      <div className="container-fluid container-sm opper-sec"> 
        <h2 className="text-center title">Opportunity Gains for the <br/><span>Consumer Brand</span></h2>
        <div className="row mt-md-3 justify-content-center">
          <div className="col-lg-4 col-10 col-sm-10">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <img src={require('../assets/images/oppertunity/revenue.svg').default} />
              <h3>Incremental Revenue Growth</h3>
              <p>Unlock new revenue streams and boost your profitability</p>
            </div>
          </div>
          <div className="col-lg-4 col-10 col-sm-10">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <img src={require('../assets/images/oppertunity/arrow.svg').default} />
              <h3>Expanded Vertical & Horizontal Reach</h3>
              <p>Broaden your market presence and explore new growth avenues</p>
            </div>
          </div>
          <div className="col-lg-4 col-10 col-sm-10">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <img src={require('../assets/images/oppertunity/journey.svg').default} />
              <h3>Complete Customer Journeys & Experiences</h3>
              <p>Enhance customer satisfaction and build strong loyalty</p>
            </div>
          </div>
        </div>
      </div>
      </section>
      {/* Idea */}
      <IdeaSection />
    </>
  );
};

export default ForHosts;  
