import React from 'react';
import Modal from 'react-bootstrap/Modal';
import video1 from '../../assets/videos/supergryd.mp4';

function ModalVideo(props) {
    return (
      <> 
        <Modal show={props.isShow||false} onHide={(e) => props.toggleModal(e, false)} className="mod-video" backdrop="static">
            <div data-bs-theme="dark"><Modal.Header closeButton></Modal.Header></div>
            <Modal.Body className="p-0">
                {/* <div style={{width: '100%', height:600, overflow: 'hidden'}}>
                <video src={video1} controls="controls" width={'100%'} autoPlay="true" />
                </div> */}
                <div className="iframe-vd-cont">
                    <iframe
                        // height="315"
                        src={video1}
                        // srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${video1}></a>`}
                        title="SuperGryd"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                    </iframe>
                </div>
            </Modal.Body>
        </Modal>
      </>
    );
}
  
export default ModalVideo;